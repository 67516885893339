import React from "react";

import "./style.css";

import Vizitka from "./Vizitka.js";
import Logo from "./Logo.js";
import Otviracka from "./Otviracka.js";
import OtvirackaOut from "./OtvirackaOut.js";

const Header = () => {
  const announcement =
    new Date("2025-02-07T23:00:00").getTime() > new Date().getTime();
  const text = (
    <div className="col-11 headerSubContainer">
      6.2.2025 - 7.2.2025 máme zavřeno.<br></br>
      Děkujeme za pochopení.
    </div>
  );

  return (
    <>
      <div className="container headerContainer">
        <div className="row justify-content-center align-items-center">
          <div className="col-sm-6 col-md-4 col-lg-4">
            <Logo />
          </div>
          <div className="col-sm-6 col-md-4 col-lg-4">
            <a href="tel:+420775737982">
              <Vizitka />
            </a>
          </div>
          <div className="col-md-4 col-lg-4">
            <Otviracka />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-center align-items-center">
          {!announcement && <div className="col-11  headerSubContainerFalse" />}
          {announcement && text}
        </div>
        <div className="row otvirackaMimo">
          <OtvirackaOut />
        </div>
      </div>
    </>
  );
};
export default Header;
